<template>
    <div class="profile-container">
        <div class="top">
            <img class="logo" src="~/assets/img/world/profile.png"/>
            <span class="title">プロフィール編集</span>
        </div>
            <div class="content">
                <v-img class="icon" :aspect-ratio="1"  @click="changeImage" :src="previewurl" cover></v-img>
                <v-file-input
                    ref="fileInput"
                    style="display: none;"
                    accept="image/*"
                    label="アイコン画像をアップロード"
                    v-model="imageIcon"
                    variant="outlined"
                    show-size
                    prepend-icon=""
                    clearable
                    chips>
                </v-file-input>
                <v-text-field class="input_info" label="ニックネーム" variant="outlined"  type="text" v-model="user.name" hide-details=""></v-text-field>
                <v-textarea class="input_info" label="ひとこと自己紹介" variant="outlined" v-model="user.desc" hide-details=""></v-textarea>
            <div class="list">
                <span>苦手なボケ</span>
                <v-checkbox v-model="user.selected" label="下品" value="dirty" hide-details></v-checkbox>
                <v-checkbox v-model="user.selected" label="暴言" value="violence" hide-details></v-checkbox>
            </div>
            
            </div>
            <CommonWorldBokeButton class="btn" @click="send_setting">保存して戻る</CommonWorldBokeButton>
            <CommonWorldBokeButton class="btn" @click="logout">ログアウト</CommonWorldBokeButton>
            
    </div>
</template>
<script setup>
import { ref } from 'vue';
const battle = useBattleStore();

const emits = defineEmits();
const haschange = ref(false);
const imageIcon = ref(null);
const config = useRuntimeConfig();

const fileInput = ref(null);

const previewurl = computed(() => {
    if (!imageIcon.value|| (Array.isArray(imageIcon.value) && imageIcon.value.length <= 0) ) 
    {
       if(user.icon){
           return config.public.iconBaseUrl + user.icon;
       }else{
        return "/img/iconDefault.png"
       }
       return null;
    }
    return URL.createObjectURL(imageIcon.value[0]);
})

const user = reactive({
    id: battle.auth.user.id,
    name: battle.auth.user.name,
    desc: battle.auth.user.desc,
    icon: battle.auth.user.icon,
    selected: battle.auth.user.selected,
});

function changeImage(){
    fileInput.value.click();
}

function send_setting(){
    if(!haschange){
        close();
        return;
    }
    if (!imageIcon.value|| (Array.isArray(imageIcon.value) && imageIcon.value.length <= 0) ){
        console.log('no image');
        useUpdateMe(user,null);
        emits('closeProfile');
    }else{
        // console.log('image+++');
        var image = new Image();
        image.src = URL.createObjectURL(imageIcon.value[0]);
        image.onload = function() {
            resizeAndCropCenter(image, 128, 128, (resultCanvas) => {
            canvasToBlob(resultCanvas, (blob) => {
                useUpdateMe(user,blob);
                emits('closeProfile');
                });
            });
        URL.revokeObjectURL(image.src);
        };
    }
}
// const route = useRoute()
// const showForceExit = computed(() => {
//     console.log(route.name);
//     if(route.name == 'world-battle'&&battle.battle_info.battle_chan!=""
//     &&battle.battle_info.status!==BattleStatus.finished
//     &&battle.battle_info.status!==BattleStatus.userstop
//     &&battle.battle_info.status!==BattleStatus.forcestop)
//     {
//         return true;
//     }else{
//         return false;
//     }
// });



function close(){
    emits('closeProfile');
}
function logout(){
    battle.setResetAll();
    navigateTo('/world/login');
}

watch(user, () => {
    if(haschange.value){return;}
    haschange.value = true;
});
watch(imageIcon, () => {
    if(haschange.value){return;}
    haschange.value = true;
});
</script>
<style lang="scss" scoped>
.profile-container{
    position: relative;
    background: $base-room-color;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    max-width: 500px;
    min-height: 100dvh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
    &::-webkit-scrollbar {
        display: none; 
      }
    >.top{
        width: 100%;
        padding: 20px;
        position: sticky;
        top: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        background: $base-room-color;
        >.logo{
           width: 40px;
        }
        >.title{
            font-family: "GN-KillGothic-U-KanaO";
            -webkit-text-stroke: 1px $word-outer-color;
            color: #ffcf00;
            font-size: 20px;
        }
    }

    >.content{
        background: #ffcf00;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        >.input_info{
            width: 100%;
            max-width: 300px;
            background: #fff;
            border-radius: 5px;
        }
        >.list{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0 10%;
            >span{
                font-family: "GN-KillGothic-U-KanaO";
                -webkit-text-stroke: 1px $word-outer-color;
                color: #ffcf00;
                font-size: 16px;
            }
        }
    }

   
    :deep(.icon) {
        border-radius: 9999px;
        max-width: 200px;
        min-width: 60px;
        // padding: 10px;
        > img {
            padding: auto;
            border: 1px solid #2AB0B0;
            border-radius: 9999px;
        }
        
    }
}
</style>